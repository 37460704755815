import { Pipe, PipeTransform } from '@angular/core';
import TimeAgo from 'javascript-time-ago';
import { TranslationService } from '../services/translation.service';

import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import fr from 'javascript-time-ago/locale/fr';
import it from 'javascript-time-ago/locale/it';
import pt from 'javascript-time-ago/locale/pt';
import my from 'javascript-time-ago/locale/my';
import sw from 'javascript-time-ago/locale/sw';
import sq from 'javascript-time-ago/locale/sq';
import ko from 'javascript-time-ago/locale/ko';
import es_mx from 'javascript-time-ago/locale/es-MX';

import { HttpService } from '../services/http.service';

TimeAgo.addLocale(en);
TimeAgo.addLocale(es);
TimeAgo.addLocale(pt);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(de);
TimeAgo.addLocale(it);
TimeAgo.addLocale(my);
TimeAgo.addLocale(sw);
TimeAgo.addLocale(sq);
TimeAgo.addLocale(ko);
TimeAgo.addLocale(es_mx);

export function fromNow(date: string | number | Date, now?: string | number | Date): number {
    return now != null
        ? new Date(now).getTime() - new Date(date).getTime()
        : new Date().getTime() - new Date(date).getTime() - HttpService.serverTimeDifference;
}

export function timeAgo(date: string | Date, language?: string) {
    return new TimeAgo(language).format(new Date(new Date(date).getTime() + HttpService.serverTimeDifference - 1000));
}

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform {

    private timeAgo: TimeAgo;
    private cache;
    private cacheTime;
    private currentValue: Date | string;

    constructor(private translationService: TranslationService) {
        this.timeAgo = new TimeAgo(this.translationService.getLanguage());
    }

    transform(inputValue: Date | string, relativeToTimestamp: Date | string | number) {
        const now = Date.now();
        if (!this.cache || now > this.cacheTime || inputValue !== this.currentValue) {
            this.currentValue = inputValue;
            const value = new Date(inputValue); // Don't mutate input

            let offset = HttpService.serverTimeDifference;
            if (relativeToTimestamp) {
                offset = now - (new Date(relativeToTimestamp).getTime() || now);
                if (offset < 0) console.warn('timeago offset less than 0', offset);
            }

            // if relative time is passed in, we add difference between now and the relative time to the value
            // and pass that into TimeAgo, so TimeAgo reads as if it is respecting the relative time
            const valueWithOffset = new Date(value.getTime() + offset);
            this.cache = value.getTime()
                ? this.timeAgo.format(valueWithOffset)
                : this.translationService.getImmediate('generics.never');
            if (value.getTime()) this.cacheTime = now + Math.abs(now - value.getTime()) / 60;
        }
        return this.cache;
    }
}
